import React from "react";
import AwaitingMeetingJoinComponent from "../../components/AwaitingMeetingJoin";

const index = () => {
  return (
    <>
      <AwaitingMeetingJoinComponent />
    </>
  );
};

export default index;
