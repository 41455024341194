import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Header from "./components/Header";
import StartInstantMeeting from "./pages/StartInstantMeeting";
import "./App.css";
import MeetingJoinBySharableId from "./pages/MeetingJoinBySharableId";
import AwaitingMeetingJoin from "./pages/AwaitingMeetingJoin";
import LeftMeeting from "./pages/LeftMeeting";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import BASE_URL from "./utilities/baseUrl";

// Loading component to display a message or redirect while loading
const Loading = ({ message, redirectTo = null }) => {
  useEffect(() => {
    if (redirectTo) {
      window.location.replace(redirectTo);
    }
  }, [redirectTo]);

  return (
    <>
      <CssBaseline />
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item>
          <CircularProgress style={{ marginRight: "30px" }} />
        </Grid>
        <Grid item>{message}</Grid>
      </Grid>
    </>
  );
};

const App = () => {
  const [isAuthorized, setIsAuthorized] = useState(null); // Track authorization status

  // Function to check authorization
  const authorize = async () => {
    const user = localStorage.getItem("user");
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");
    const refreshToken = urlParams.get("refreshToken");

    if (user) {
      setIsAuthorized(true);
    } else if (userId || refreshToken) {
      try {
        const formData = new FormData();
        formData.append("id", userId);
        const response = await fetch("https://accounts.clikkle.com:5000/api/auth/get_user_profile", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const userData = await response.json();
          localStorage.setItem("user", JSON.stringify(userData)); // Store user data in localStorage
          setIsAuthorized(true);
          window.location.replace("https://crew.clikkle.com"); // Redirect to main app
        } else {
          console.log("Failed to fetch user profile:", response.statusText);
          setIsAuthorized(false); // User is not authorized if the API fails
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setIsAuthorized(false);
      }
    } else {
      setIsAuthorized(false); // If neither user nor valid params, set unauthorized
    }
  };

  useEffect(() => {
    authorize(); // Run authorization check on component mount
  }, []);

  // If authorization status is null, show the loading spinner
  if (isAuthorized === null) {
    return <Loading message="Checking authorization..." />;
  }

  // If not authorized, redirect to the login page
  const redirectTo = `https://accounts.clikkle.com/login?redirectto=${encodeURIComponent('https://crew.clikkle.com/')}&referrer=${'https://crew.clikkle.com/'}`;

  if (isAuthorized === false) {
    return (
      <Loading
        message="Redirecting to login..."
        redirectTo={redirectTo} // Use the calculated redirectTo here
      />
    );
  }

  // Render the main app content once authorized
  return (
    <>
      <Header>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/startinstantmeeting/:meetingId"
            element={<StartInstantMeeting />}
          />
          <Route
            path="/joinbylink/:meetingId"
            element={<MeetingJoinBySharableId />}
          />
          <Route path="/leftmeeting/:meetingId" element={<LeftMeeting />} />
          <Route
            path="/awaitingmeetingjoin/:meetingId"
            element={<AwaitingMeetingJoin />}
          />
        </Routes>
      </Header>
    </>
  );
};

export default App;
