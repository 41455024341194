import { Popover } from "@mui/material";
import React from "react";
import BackHandIcon from "@mui/icons-material/BackHand";
import MoodIcon from "@mui/icons-material/Mood";
import ClosedCaptionOffOutlinedIcon from "@mui/icons-material/ClosedCaptionOffOutlined";
import IconButtonWithToolTip from "../common/IconButtonWithToolTip";
import styles from "../../style/startInstantMeeting.module.css";

const MoreOptions = ({ anchorEl, handleClose, open }) => {
  const id = open ? "more-options-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "top",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "top",
      }}
     
    >
      <IconButtonWithToolTip
        btnIccon={<ClosedCaptionOffOutlinedIcon />}
        tootTip={"Turn on captions"}
        className={styles.callToActionButtonStyle}
      />
      <IconButtonWithToolTip
        btnIccon={<MoodIcon />}
        tootTip={"Send a reaction"}
        className={styles.callToActionButtonStyle}
      />
      <IconButtonWithToolTip
        btnIccon={<BackHandIcon />}
        tootTip={"Raise hand"}
        className={styles.callToActionButtonStyle}
      />
    </Popover>
  );
};

export default MoreOptions;
