import { roomUsers } from "./action";
import {
  MEETING_ACTION_TYPE,
  SCREEN_RECORD_ON,
  SHOW_SCREENSHAREPREVIEW_SCREEN,
  VIDEO_CAMERA_ON,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_ERROR,
  NEW_MESSAGE,
  DISPLAY_MEDIA,
  UPDATE_STREAMING_STATUS,
  BROADCAST_MESSAGE,
  ROOM_USERS,
  AUDIO_ON,
  REMOTE_STREAMS,
  ADD_VIDEO_STREAM,
  REMOVE_VIDEO_STREAM,
  SCREEN_SHARING,
  JOIN_STATUS
} from "./actionType";

const initialState = {
  meetingActivyAction: null,
  videoCameraOn: true,
  audioOn: true,
  screenRecordOn: false,
  screenSharePreviewScreen: null,
  isConnected: false,
  error: null,
  messages: [],
  displayMedia: false,
  streaming: false,
  roomUsers:[],
  screenS: {},
  remoteStreams:[],
  videoStreams: [],
  joinstaus:''
};
export const reducerMeetingType = (state = initialState, action) => {
  switch (action.type) {
    case MEETING_ACTION_TYPE:
      return {
        ...state,
        meetingActivyAction: action.payload,
      };
    case VIDEO_CAMERA_ON:
      return {
        ...state,
        videoCameraOn: action.payload,
      };
      case JOIN_STATUS:
        return {
          ...state,
          joinstaus: action.payload,
        };
      case REMOTE_STREAMS:
        return { ...state,
           remoteStreams:action.payload
           };
      case AUDIO_ON:
        return {
          ...state,
          audioOn: action.payload,
        };
    case SCREEN_RECORD_ON:
      return {
        ...state,
        screenRecordOn: action.payload,
      };
      case SCREEN_SHARING:
        return {
          ...state,
          screenS: action.payload,
        };
    case SHOW_SCREENSHAREPREVIEW_SCREEN:
      return {
        ...state,
        screenSharePreviewScreen: action.payload,
      };
      case ADD_VIDEO_STREAM:
      // Check if the video stream for the user already exists
      const existingStream = state.videoStreams.find(
        (item) => item.userId === action.payload.userId
      );
      if (existingStream) {
        return state; // Don't add the stream if it already exists
      }
      
      // Add the new video stream if it doesn't already exist
      return {
        ...state,
        videoStreams: [...state.videoStreams, action.payload],
      };
      case REMOVE_VIDEO_STREAM:
        return {
          ...state,
          videoStreams: state.videoStreams.filter(
            (stream) => stream.userId !== action.payload.userId
          ),
        };
    default:
      return state;
  }
};


export const socketReducer = (state = initialState, action) => {
  switch (action.type) {
      case SOCKET_CONNECT:
          return { ...state, isConnected: true };
      case SOCKET_DISCONNECT:
          return { ...state, isConnected: false };
      case SOCKET_ERROR:
          return { ...state, error: action.payload };
      case NEW_MESSAGE:
          return { ...state, messages: [...state.messages, action.payload] };
      case ROOM_USERS:
          return { ...state, roomUsers:action.payload };
      case DISPLAY_MEDIA:
          return { ...state, displayMedia: action.payload };
      case UPDATE_STREAMING_STATUS:
          return { ...state, streaming: action.payload };
          case BROADCAST_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload], // Append the new message to the list
            };
      default:
          return state;
  }
};
