import React, { useEffect, useState } from "react";
import styles from "../../style/mediaRecorder.module.css";
import UserIconWithName from "./UserIconWithName";
import VideoRecorder from "./VideoRecorder";
import { useDispatch, useSelector } from "react-redux";
import ScreenRecorder from "./ScreenRecorder";
import { AnswerUser, callUser, dicconectedPeer } from "../../store/socketThunks";
import { Avatar } from "@mui/material";
const MediaRecorder = ({ stopScreenSharing, startScreenSharing }) => {
  const dispatch = useDispatch();
  const { meetingActivyAction, videoStreams, screenRecordOn, screenS } = useSelector(
    (state) => state.reducerMeetingType
  );
 // console.log(screenS,"screenS")
  const { roomUsers } = useSelector(state => state.socketReducer);

  const [USer, setUSer] = useState({})

  useEffect(() => {
    const userString = localStorage.getItem('user')
    const userData = JSON.parse(userString).user
    setUSer(userData)
  }, [])


  const renderVideo = (videoData) => (
    <div className={styles.userbox} style={{ width: "100%", height: "48%" }}>
      <video
        muted={videoData.id === USer._id}
        ref={(videoRef) => {
          if (videoRef && videoRef.srcObject !== videoData.stream) {
            videoRef.srcObject = videoData.stream;
            videoRef.play().catch((error) => {
              console.error('Error playing video:', error);
            });
          }
        }}
        style={{ width: '100%', height: "100%", objectFit: 'fill' }}
      />
      <div style={{
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '2px 5px',
        borderRadius: '3px',
        fontSize: '12px'
      }}>
        {videoData.userName}
      </div>
    </div>
  );

  return (
    <div
      className={`${styles.mediaRecorderContainer} ${meetingActivyAction ? styles.mediaRecorderContainerResponsive : ""
        }`}
    >
      {
        screenRecordOn ? (
          <>
            <div className={styles.screenRecorderContainer}>
              <ScreenRecorder meetingActivyAction={meetingActivyAction} startScreenSharing={startScreenSharing} stopScreenSharing={stopScreenSharing} />
            </div>
            <div className={videoStreams.length === 1 ? styles.singleUser : styles.right}>
              {videoStreams.map((videoData, index) => (
                <div className={styles.userbox} style={{ width: "100%", height: "48%" }}>

                  <video
                    muted={videoData.id === USer._id}
                    ref={(videoRef) => {
                      if (videoRef) {
                        // Check if the video source is already set
                        if (videoRef.srcObject !== videoData.stream) {
                          // If it's not, update the srcObject
                          videoRef.srcObject = videoData.stream;


                          // Once the srcObject is set, try to play the video
                          videoRef.play().catch((error) => {
                            console.error('Error playing video:', error);
                          });
                        }
                      }
                    }}
                    style={{ width: '100%', height: "100%", objectFit: 'fill' }}
                  />
                  <div style={{
                    position: 'absolute',
                    bottom: '5px',
                    left: '5px',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '2px 5px',
                    borderRadius: '3px',
                    fontSize: '12px'
                  }}>
                    {videoData.userName}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (

          screenS && screenS.screenRecordOn ? (
            <>
              <div className={styles.screenRecorderContainer}>
                {videoStreams.some(videoData => videoData.id === screenS.id) && (
                  <div>{renderVideo(videoStreams.find(videoData => videoData.id === screenS.id))}</div>
                )}
              </div>
              <div className={videoStreams.length === 1 ? styles.singleUser : styles.right}>
                {videoStreams
                  .filter(videoData => videoData.id !== screenS.id)
                  .map((videoData, index) => (
                    <div key={index}>{renderVideo(videoData)}</div>
                  ))}
              </div>
            </>
          ):
          (<div
            className={
              videoStreams.length === 1
                ? styles.userListContainerSingle
                : videoStreams.length === 2
                  ? styles.userListContainerTwo
                  : styles.userListContainerGrid
            }
          >
            {videoStreams.map((videoData, index) => { 
              return(
              <div key={index}
                className={
                  videoStreams.length === 1
                    ? styles.userBoxFull
                    : videoStreams.length === 2 && index === 0
                      ? styles.userBoxFull
                      : videoStreams.length === 2 && index === 1
                        ? styles.userBoxSmall
                        : styles.userBoxGrid
                }
                style={
                  videoStreams.length >= 3
                    ? {
                      width: `${100 / videoStreams.length}%`,
                      height: `${100 / videoStreams.length}%`,
                    }
                    : {}
                }>


               <video
                  autoPlay
                  muted={videoData.id === USer._id}
                  ref={(videoRef) => {
                    if (videoRef) {
                      videoRef.muted = videoData.id === USer._id;
                      if (videoRef.srcObject !== videoData.stream) {
                        videoRef.srcObject = videoData.stream;
                        videoRef.play().catch((error) => {
                          console.error('Error playing video:', error);
                        });
                      }
                    }
                  }}
                  style={{ width: '100%', height: "100%", objectFit: 'fill' }}
                />
                

                <div style={{
                  position: 'absolute',
                  bottom: '5px',
                  left: '5px',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  padding: '2px 5px',
                  borderRadius: '3px',
                  fontSize: '12px'
                }}>
                  {videoData.userName}
                </div>
              </div>
            )
            }
            )}
          </div>)
        )
      }
    </div>
  );
};
export default MediaRecorder;