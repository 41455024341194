import React from "react";
import StartInstantMeetingComponent from "../../components/StartInstantMeeting";

const Index = () => {
  return (
    <>
      <StartInstantMeetingComponent />
    </>
  );
};

export default Index;
