import React from "react";
import MeetingJoinByIdComponent from "../../components/MeetingJoinBySharableId";

const index = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <MeetingJoinByIdComponent />
    </div>
  );
};

export default index;
