import {
  MEETING_ACTION_TYPE,
  SCREEN_RECORD_ON,
  SHOW_SCREENSHAREPREVIEW_SCREEN,
  VIDEO_CAMERA_ON,
  AUDIO_ON,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_ERROR,
  NEW_MESSAGE,
  DISPLAY_MEDIA,
  UPDATE_STREAMING_STATUS,
  BROADCAST_MESSAGE,
  ROOM_USERS,
  REMOTE_STREAMS,
  ADD_VIDEO_STREAM,
  REMOVE_VIDEO_STREAM,
  SCREEN_SHARING,
  JOIN_STATUS
} from "./actionType";



export const addVideoStream = (userId, stream, userName,id) => ({
  type: ADD_VIDEO_STREAM,
  payload: { userId, stream, userName,id },
});

export const removeVideoStream = (userId) => ({
  type: REMOVE_VIDEO_STREAM,
  payload: { userId },
});
export const meetingActivity = (data) => {
  return {
    type: MEETING_ACTION_TYPE,
    payload: data,
  };
};
export const videoCameraOnAction = (data) => {
  return {
    type: VIDEO_CAMERA_ON,
    payload: data,
  };
};
export const remoteStreamsOnAction = (data) => {
  return {
    type: REMOTE_STREAMS,
    payload: data,
  };
};

export const audioAction = (data) => {
  return {
    type: AUDIO_ON,
    payload: data,
  };
};
export const screenRecordAction = (data) => {
  return {
    type: SCREEN_RECORD_ON,
    payload: data,
  };
};
export const screenSharePreviewAction = (data) => {
  return {
    type: SHOW_SCREENSHAREPREVIEW_SCREEN,
    payload: data,
  };
};

export const joinR = (data) => {
  return {
    type: JOIN_STATUS,
    payload: data,
  };
};


export const socketConnect = () => ({ type: SOCKET_CONNECT });
export const socketDisconnect = () => ({ type: SOCKET_DISCONNECT });

export const socketError = (error) => ({ type: SOCKET_ERROR, payload: error });
export const newMessage = (message) => ({ type: NEW_MESSAGE, payload: message });
export const screen = (data) => ({ type: SCREEN_SHARING, payload: data });
export const roomUser = (data) => ({ type: ROOM_USERS, payload: data });
export const displayMedia = (data) => ({ type: DISPLAY_MEDIA, payload: data });
export const updateStreamingStatus = (status) => ({ type: UPDATE_STREAMING_STATUS, payload: status });


export const broadcastMessageAction = (message) => {
  return {
      type: BROADCAST_MESSAGE,
      payload: message,
  };
};