import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Axios from 'axios';
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import KeyboardOutlinedIcon from "@mui/icons-material/KeyboardOutlined";
import Image from "../../components/Image";
import { useMediaQuery } from '@mui/material';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled, alpha } from "@mui/material/styles";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useModal from "../../hooks/useModal";
import { useNavigate } from "react-router-dom";
import { joinR } from "../../store/action";
import { useDispatch } from "react-redux";
import BASE_URL from "../../utilities/baseUrl";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Index = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [inputLink, setInputLink] = useState("");
  const [meetingUrl, setMeetingUrl] = useState("")
  
  const handleInputChange = (event) => {
    setInputLink(event.target.value);
  };

  const handleJoinClick = () => {
    if (inputLink) {
      navigate("startinstantmeeting/"+inputLink); // navigate to the link entered by the user
    } else {
      alert("Please enter a valid code or link.");
    }
  };

  /**This function is redirect to start a instant meetng page */


  const redirectToInstantMeetingpage = () => {
    const userString = localStorage.getItem('user')
    const us = JSON.parse(userString).user
    Axios.get(BASE_URL+'/api/v1/join/'+us._id)
      .then(res => {
        dispatch(joinR('creation'));
        navigate(`/startinstantmeeting/${res.data.link}`);
      })
      .catch(error => {
        console.error('Error joining:', error);
      });
  };

  const {
    state: createMeeting,
    openModal: openMeeting,
    closeModal: closeMeeting,
  } = useModal();
  const settings = {
    dots: true,
    infinite: false,
    fade: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleLaterMeeting = () => {
    const userString = localStorage.getItem('user')
    const us = JSON.parse(userString).user
    Axios.get(BASE_URL+'/api/v1/join/'+us._id)
    .then(res => {
      setMeetingUrl("https://crew.clikkle.com/"+"startinstantmeeting/"+res.data.link)
        openMeeting();
    handleClose();
    })
    .catch(error => {
        console.error('Error joining:', error);
    });
    
  };
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(meetingUrl);
     // alert("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  const isMobile = useMediaQuery('(max-width: 1279px)');
  return (
    <>
      <Box display="flex" alignItems="center" minHeight="100%" width="100%">
        <Grid container spacing={1} display="flex" alignItems="center">
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                mx: "auto",
                maxWidth: { md: 590, xs: 450, sm: 500 },
                textAlign: { md: "left", xs: "center" },
                paddingBottom:{xs:"45%",lg:"10%"},
              }}
            >
              <Typography
                variant="h3"
                sx={{
                fontSize: { xs: "clamp(25px, 6.5vw, 2.75rem)", lg: "clamp(25px,5vw, 3.0rem)" },paddingTop: { xs: "18%", lg: "unset" },paddingRight: { xs: "5%", lg: "30%" }, 
                mx: { xs: 2, lg: 0 },
                color: { xs: "#d1d5db", lg: "#d1d5db" }
              }}
              >
                Video Calls and meetings for everyone
              </Typography>
              <Box my={4} >
                <Typography
                  sx={{ fontSize: { xs: "0.8rem", lg: "1.2rem" }, px:{xs: 3, lg:"unset"},display: { xs: 'block', lg: 'block' },paddingRight: { xs: "5%", lg: "10%" }, }} 
                  color="#d1d5db"
                >
                  Clikkle Crew is one service for secure high-quality video 
                  meetings and calls available for everyone, On any device.
                </Typography>
              </Box>
             {isMobile ? (
              <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/**image */}
          <Button
            variant="contained"
            sx={{
              fontWeight: 600,
              py: 1,
              width: '100%',
              mr: 2
            }}
            startIcon={<VideocamOutlinedIcon />}
            onClick={handleClick}
          >
            New meeting
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLaterMeeting}>
              <InsertLinkIcon />
              Create a meeting for later
            </MenuItem>
            <MenuItem onClick={redirectToInstantMeetingpage}>
              <AddIcon />
              Start an instant meeting
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <CalendarTodayIcon />
              Schedule in Google Calendar
            </MenuItem>
          </StyledMenu>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            fullWidth
            id="outlined-start-adornment"
            placeholder="Enter a code or link"
            value={inputLink}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyboardOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
           onClick={handleJoinClick}
          sx={{ fontWeight: 600, py: 1, width: '100%', color: '#d1d5db' }}>
            Join
          </Button>
        </Grid>
      </Grid>
    </Grid>
             ) : (
              <Grid container spacing={1} >
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ fontWeight: 600, py: 1 }}
                      startIcon={<VideocamOutlinedIcon />}
                      onClick={handleClick}
                    >
                      New meeting
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleLaterMeeting}>
                        <InsertLinkIcon />
                        Create a meeting for later
                      </MenuItem>
                      <MenuItem onClick={redirectToInstantMeetingpage}>
                        <AddIcon />
                        Start an instant meeting
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <CalendarTodayIcon />
                        Schedule in Google Calender
                      </MenuItem>
                    </StyledMenu>
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-start-adornment"
                      placeholder="Enter a code or link"
                      value={inputLink}
            onChange={handleInputChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyboardOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button   onClick={handleJoinClick}
                    variant="contained" sx={{ fontWeight: 600, py: 1 }}>
                      Join
                    </Button>
                  </Grid>
                </Grid>
            )}
              <Divider sx={{ my: { xs: 2, lg: 3 }, display: { xs: 'none', lg: 'block' } }} />
              <Typography
                color="text.secondary"
                variant="caption"
                sx={{ fontSize: "15px", display: { xs: 'none', lg: 'block' } }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "15px",
                    color: "#295C97",

                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    }, my: { xs: 3, lg: 3 }
                  }}
                >
                  Learn more
                </Typography>{" "}
                about Clikkle crew
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ textAlign: "center", paddingTop: { xs: "unset", lg: "unset" },paddingBottom: { xs: "unset", lg: "8%" },display: { xs: 'none', lg: 'block' } }}>
            <img src="https://cdn.clikkle.com/images/crew/Clikkle%20crew%20LANDING%20PAGE%20UI/Rectangle%207.png" alt="banner" style={{ width: '70%', height: '70%' }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={createMeeting}
        onClose={closeMeeting}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card sx={{ maxWidth: "300px" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Here's the link to your meeting
              </Typography>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box my={1} mb={2}>
              <Typography variant="caption" color="text.secondary">
                Copy this link and send it to people that you want to meet with.
                Make sure that you save it so that you can use it later, too.
              </Typography>
            </Box>
            <TextField
              size="large"
              fullWidth
              id="outlined-start-adornment"
              placeholder="Copy link"
              value={meetingUrl}
              sx={{ pointer: "cursor" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" onClick={handleCopyClick}>
                    <ContentCopyIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default Index;
