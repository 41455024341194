import * as React from "react";
import { Box, AppBar, Toolbar, Typography,MenuItem,ListItemIcon, Grid, IconButton, Tooltip, Button, Menu, Stack, Avatar, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import LeftSideDrawer from "./MenuLeftDrawer";
import ReportSideDrawer from "./ReportSideDrawer";
import { useTheme } from "../style/theme";
import { useMenu } from "../hooks/useMenu";
import useModal from "../hooks/useModal";
import Image from "./Image";
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useState } from "react";
import { useEffect } from "react";
import BASE_URL from "../utilities/baseUrl";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": { padding: "4px 0" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": { fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5) },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));



const Navbar = ({ children }) => {
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  
  const [platformUser, setPlatformUser] = useState({});

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser).user;
        setPlatformUser(parsedUser);
      }
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }, []); 
  const {
    anchorEl: anchorElSettings,
    openMenu: openSettingsMenu,
    closeMenu: closeSettingsMenu,
  } = useMenu();
  const [currentTime, setCurrentTime] = React.useState(new Date());
  const { toggleTheme, mode } = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerLeftOpen, setDrawerLeftOpen] = React.useState(false);

  // State for controlling the profile menu
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const isProfileMenuOpen = Boolean(profileAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };
  const signOut = () => {
   // clearCookie("accessToken");

    localStorage.removeItem("user");
    // clearCookie('role');
    // clearCookie('setupCompleted');

    const redirectTo =
     "https://accounts.clikkle.com" +
      "/logout?redirectto=" +encodeURIComponent('https://crew.clikkle.com')+"&referrer="+'https://crew.clikkle.com'
    window.location.replace(redirectTo);
  };

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;
    setDrawerOpen(open);
  };

  const toggleLeftDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;
    setDrawerLeftOpen(open);
  };

  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const isLeftMeetingPage = location.pathname.includes("/leftmeeting/");

  return (
    <>
      {!isLeftMeetingPage && <ReportSideDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />}
      <Box sx={{ px: { xs: 0.5, xm: 0 }, height: "100dvh", position: "relative" }} id="headerm">
        {!isLeftMeetingPage && (
          <AppBar elevation={0} component={Box} position="sticky" sx={{ bgcolor: "background.default", color: "inherit" }}>
            <Toolbar sx={{ flexDirection: "column", justifyContent: "center", position: "relative", "&": { minHeight: "64px", px: 0, width: isMobile && (location.pathname.startsWith("/awaitingmeetingjoin/") || location.pathname.startsWith("/joinbylink/")) ? "200%" : isLGScreen ? "100%" : "auto" } }}>
              <Grid container alignItems="center" display="flex" sx={{ borderBottom: '1px solid #3f3f46' }}>
                <Grid item xs>
                  <Box display="flex" alignItems="center" position="relative" component={Link} to="/" sx={{ textDecoration: "none", color: "text.primary", py: 0.5, px: { xs: 0, lg: 2 } }}>
                    <IconButton onClick={toggleLeftDrawer(true)}>
                      <MenuIcon />
                    </IconButton>
                    <Image src="https://cdn.clikkle.com/images/crew/logo/2023/crew.png" sx={{ height: "32px", width: "32px", marginLeft: "12px" }} />
                    <Typography color="#f4f4f5" fontWeight="bold" fontSize={{ xs: "18px", lg: "30px" }} paddingLeft={{ xs: "5px", lg: "8px" }}>Clikkle</Typography>
                    <Typography color="#f4f4f5" fontSize={{ xs: "18px", lg: "30px" }} paddingLeft={{ xs: "5px", lg: "8px" }}>Crew</Typography>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={0}>
                    <Box display={{ xs: "none", lg: "none" }}>
                      <Typography variant="body1" color="text.tertiary" sx={{ mt: 1.3 }}>{`${hours}:${minutes < 10 ? "0" : ""}${minutes}`}</Typography>
                      <Typography variant="body1" color="text.tertiary" sx={{ mx: 2, mt: 1.3 }}>{new Date().toDateString()}</Typography>
                    </Box>
                     {!isMobile && (
                      <>
                      <IconButton onClick={openSettingsMenu} >
                  <SettingsIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorElSettings}
                  open={Boolean(anchorElSettings)}
                  onClose={closeSettingsMenu}
                >
                  <MenuItem onClick={toggleTheme}>
                    <ListItemIcon>
                      {mode === "dark" ? (
                        <LightModeIcon fontSize="small" />
                      ) : (
                        <DarkModeIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    Appearance
                  </MenuItem>
                </Menu>
                       <IconButton sx={{ mr: 0.5}} target="_blank" href={"https://apps.clikkle.com/"}>
                  <AppsIcon />
                </IconButton>
                      </>
                    )} 
                  <Grid item>
              <IconButton
                onClick={handleProfileMenuOpen}
                sx={{
                  borderWidth: "2px",
                  width
                  :'45px',
                  borderStyle: "solid",
                  borderColor: "primary.main",
                  p: "3px",
                  mr: 2,
                }}
              >
                   <Typography
    variant='subtitle1' // Corrected 'substitle1' to 'subtitle1'
    component='div'
    fontWeight={600}
    sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }}>
    {platformUser && platformUser.firstName && platformUser.lastName
        ? platformUser.firstName.charAt(0).toUpperCase() + platformUser.lastName.charAt(0).toUpperCase()
        : '?'}
</Typography>
              </IconButton>

              <Menu
                anchorEl={profileAnchorEl}
                open={isProfileMenuOpen} 
                onClose={handleProfileMenuClose}
                sx={{
                  ".MuiPaper-root.MuiMenu-paper.MuiPopover-paper": {
                    width: "min(100%, 320px)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                    border: "1px solid #00000017",
                    bgcolor: "custom.menu",
                    px: 0.5,
                    pt: 1.5,
                  },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  flexWrap="nowrap"
                >
                  <Grid sx={{ borderWidth: "2px",
                  width
                  :'145px',
                  marginLeft:'22px',
                  height
                  :'85px',
                  borderRadius:'60px',
                  textAlign:'center',
                  borderStyle: "solid",
                  borderColor: "primary.main",
                  p: "3px",}} item>
                  <Typography
    variant='subtitle1' // Corrected 'substitle1' to 'subtitle1'
    component='div'
    fontWeight={600}
    sx={{
      fontSize:'30px',
      fontFamily:'sans-serif',
      marginLeft:'-12px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }}>
    {platformUser && platformUser.firstName && platformUser.lastName
        ? platformUser.firstName.charAt(0).toUpperCase() + platformUser.lastName.charAt(0).toUpperCase()
        : '?'}
</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="substitle1"
                      component="div"
                      fontWeight={600}
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {platformUser?.firstName + " " + platformUser?.lastName}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {platformUser?.email}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="a"
                      href={"https://myaccount.clikkle.com/"}
                      color="primary.main"
                      display= "block"
                    >
                      My Clikkle account
                    </Typography>
                    <Typography
                      variant="caption"
                      component="a"
                      href="#"
                      color="primary.main"
                      display="block"
                    >
                      My Profile
                    </Typography>
                  </Grid>
                </Grid>
                <Stack direction="row" mt={2}>
                  <Button variant="text" fullWidth>
                    Add account
                  </Button>
                  <Button variant="text" onClick={signOut} fullWidth>
                    Sign out
                  </Button>
                </Stack>
              </Menu>
            </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        )}
        <Box component="main" sx={{ px: 2, mt: 1, height: { xs: "calc(100dvh - 62px)", sm: "calc(100dvh - 70px)" }, position: "relative" }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
