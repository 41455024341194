export const MEETING_ACTION_TYPE = "MEETING_ACTION_TYPE";
export const VIDEO_CAMERA_ON = "VIDEO_CAMERA_ON";
export const SCREEN_RECORD_ON = "SCREEN_RECORD_ON";
export const SHOW_SCREENSHAREPREVIEW_SCREEN = "SHOW_SCREENSHAREPREVIEW_SCREEN";
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const DISPLAY_MEDIA = 'DISPLAY_MEDIA';
export const UPDATE_STREAMING_STATUS = 'UPDATE_STREAMING_STATUS';
export const BROADCAST_MESSAGE = 'BROADCAST_MESSAGE';
export const ROOM_USERS = 'ROOM_USERS';
export const AUDIO_ON = 'AUDIO_ON';
export const REMOTE_STREAMS = 'REMOTE_STREAMS';
export const ADD_VIDEO_STREAM = 'ADD_VIDEO_STREAM';
export const REMOVE_VIDEO_STREAM = 'REMOVE_VIDEO_STREAM';
export const SCREEN_SHARING = 'SCREEN_SHARING';
export const JOIN_STATUS = 'JOIN_STATUS';

