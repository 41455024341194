import { Box, Button, Card, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIconWithTitle from "../../../common/CloseIconWithTitle";
import ActivityWithIcon from "./ActivityWithIcon";
import IconWithName from "./IconWithName";
import HR from "../../../../assets/hr.png";
import Ads from "../../../../assets/ads.png";
import Esign from "../../../../assets/e-sign.png";
const ActivityBox = () => {
  return (
    <Box>
      <CloseIconWithTitle title={"Activities"} />
      <Card>
        <Box p={1}>
          <Typography fontSize={16}>Features adds-on</Typography>
          <Typography fontSize={14} color={"text.secondary"}>
            Install third-party add-ons to do more with Crew
          </Typography>
        </Box>
        <Stack
          direction={"row"}
          p={2}
          pl={4}
          pr={4}
          spacing={2}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <IconWithName name={"Clikkle Ads"} src={Ads} />
          <IconWithName name={"Clikkle Esign"} src={Esign} />
          <IconWithName name={"Clikkle HR"} src={HR} />
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            margin: "10px 0",
          }}
        >
          <Button color="primary">Show More</Button>
        </Box>
      </Card>
      <Box p={1} sx={{ margin: "10px 0" }}>
        <ActivityWithIcon />
      </Box>
    </Box>
  );
};

export default ActivityBox;
