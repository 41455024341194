import { useEffect, useRef, useState } from 'react';
import styles from "../../style/joinByLink.module.css";
import { io } from "socket.io-client";
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import { useSocketConnection } from "../../hooks/useSocketConnection";
import { useNavigate } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import { joinR } from '../../store/action';
import { useDispatch } from 'react-redux';
import BASE_URL from '../../utilities/baseUrl';


const JoinComponent = () => {
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false)

  useEffect(() => {
    // Initialize Socket connection once on component mount
    const socket = io(BASE_URL);
    socketRef.current = socket;

    // Cleanup socket connection on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {

    const handleJoin = (data) => {
      const roomID = window.location.pathname.split('joinbylink/')[1];

      dispatch(joinR('room'));
      navigate(`/startinstantmeeting/${roomID}`);
    };

    socketRef.current.on('join-accepted', handleJoin);
    // Clean up the event listener when the component unmounts
    return () => {
      socketRef.current.off('join-accepted', handleJoin);
    };
  }, []);

  const handlejoin = () => {
    setloading(true)
    const roomID = window.location.pathname.split('joinbylink/')[1];
    const userString = localStorage.getItem('user')
    const user = JSON.parse(userString).user
    const userData = {
      userID: user?._id,
      userName: user?.firstName + " " + user?.lastName,
      roomID,
    };

    socketRef.current.emit('r-join-room', userData);
  }



  return (
    <div className={styles.joinCotainer}>
      <Typography variant="h4">Ready to join?</Typography>
      <br />
      <Typography variant="body" color={"text.secondary"}>
        No one else is here
      </Typography>
      {loading ?
      <Box sx={{ display: "flex" }} pt={2}>
      <CircularProgress />
    </Box>:
    <Box pt={2}>
        <Button
          variant="contained"
          sx={{ marginRight: "20px", padding: "9px 18px" }}
          color="primary"
          onClick={handlejoin}
        >
          Join now
        </Button>
        
       {/*  <IconButton
          variant="outlined"
          color="primary"
          sx={{ borderRadius: "5px", border: "1px solid grey" }}
        >
          <PresentToAllIcon />
          <Typography ml={1}>Present</Typography>
        </IconButton> */}
      </Box>
      }
      

      
      <Box mt={6} sx={{ textAlign: "center" }}>
        <Typography mb={1} color={"text.secondary"}>
          Other joining options
        </Typography>
        <Link>
          <Stack direction={"row"} textAlign={"center"}>
            <PhonelinkIcon /> <Typography ml={1}>Use Companion mode</Typography>{" "}
          </Stack>
        </Link>
      </Box>
    </div>
  );
};

export default JoinComponent;
