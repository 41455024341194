import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import BASE_URL from '../utilities/baseUrl';

export const useSocketConnection = () => {
  const socketRef = useRef(null);

  useEffect(() => {
    // Initialize Socket connection once on component mount
    const socket = io(BASE_URL);
    socketRef.current = socket;

    // Cleanup socket connection on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return socketRef;
};
