import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';  // Use named import for `thunk`
import { reducerMeetingType, socketReducer } from './reducer';

const rootReducer = combineReducers({ 
    reducerMeetingType,
    socketReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
