import React from "react";
import CloseIconWithTitle from "../../common/CloseIconWithTitle";
import { Box, Stack, SnackbarContent, Typography } from "@mui/material";
import TextWithSwith from "./TextWithSwith";
import SendMessage from "./SendMessage"; // Assuming this handles message sending
import styles from "../../../style/dialogBoxCard.module.css";
import { useSelector } from "react-redux";

const CrewChatBox = ({onSendMessage}) => {
  const { messages } = useSelector(state => state.socketReducer);

  return (
    <Box className={styles.dialogBoxCard}>
      <CloseIconWithTitle title={"In-call messages"} />
      <TextWithSwith titleSwitch={"Let everyone send messages"} />
      <Stack spacing={1} mt={2}>
        <SnackbarContent
          sx={{ textAlign: "center", fontSize: "14px" }}
          message="Messages can only be seen by people in the call and are deleted when the call ends"
        />
      </Stack>
      <Box className={styles.messageContainer}>
        {messages.length !== 0 ? (
          messages.map((message, index) => (
            <Stack key={index}  >
              <Typography sx={{paddingBottom:"15px"}}>
                <strong>{message.userData.firstName}</strong>
                <br/>
                {message.message}
              </Typography>
                
              
            </Stack>
          ))
        ) : (
          <Typography sx={{ textAlign: "center", color: "gray" }}>
            No messages yet.
          </Typography>
        )}
      </Box>
      
        <SendMessage onSendMessage={onSendMessage} /> 
    
    </Box>
  );
};

export default CrewChatBox;
