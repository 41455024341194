
import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import VideoComponent from "../MeetingJoinBySharableId/VideoComponent";
import JoinComponent from "./JoinComponent";
const MyComponent = () => {
 
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      {isMobile ? (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          position="relative"
          height="100vh"
          width="200%"
          p={{ xs: 2, sm: 5, md: 10 }}
          pl={{ xs: 4, sm: 5, md: 10 }}
        >
         
          <Grid item xs={12} md={7} sm={12}>
            <VideoComponent />
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 5, sm: 3 }}>
            <JoinComponent/>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          p={{ xs: 2, sm: 5, md: 10 }}
          pl={{ xs: 2, sm: 5, md: 10 }}
        >
          
          <Grid item xs={12} md={7} sm={12}>
            <VideoComponent />
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 5, sm: 3 }}>
            <JoinComponent/>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MyComponent;
