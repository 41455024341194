import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "../../style/startInstantMeeting.module.css";

const MeetingIdAndDate = () => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (isMobileScreen) {
    return null; 
  }
  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight and adjust to 12-hour format
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
  return (
    <Box className={styles.meetingIdAndDate} display="flex">
      <Typography color="text.primary">{getCurrentTime()}</Typography>
      <Typography color="text.primary" className={styles.dividerIcon}>
        |
      </Typography>
      <Typography color="text.primary">{window.location.pathname.split('startinstantmeeting/')[1]}</Typography>
    </Box>
  );
};

export default MeetingIdAndDate;
