import React, { useState } from "react";

import { Box, Popover } from "@mui/material";
import MicNoneOutlined from "@mui/icons-material/MicNoneOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreOptions from "./MoreOptions";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import StopScreenShareIcon from "@mui/icons-material/StopScreenShare";
import { useDispatch, useSelector } from "react-redux";
import { audioAction, screenRecordAction, videoCameraOnAction } from "../../store/action";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import IconButtonWithToolTip from "../common/IconButtonWithToolTip";
import MoreAction from "./MoreAction";
import styles from "../../style/startInstantMeeting.module.css";
import BackHandIcon from "@mui/icons-material/BackHand";
import MoodIcon from "@mui/icons-material/Mood";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MicOutlined from "@mui/icons-material/MicOutlined";
import MicOffOutlined from "@mui/icons-material/MicOffOutlined";
import ClosedCaptionOffOutlinedIcon from "@mui/icons-material/ClosedCaptionOffOutlined";

const CallAndActionSection = () => {
  const { videoCameraOn, screenRecordOn ,audioOn} = useSelector(
    (state) => state.reducerMeetingType
  );
  
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = React.useState(null);
  const handleMoreOptionsClick = (event) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };
  const handleMoreOptionsClose = () => {
    setMoreOptionsAnchorEl(null);
  };

 

  const navigate = useNavigate();
  const { meetingId } = useParams();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const videoCamerActionHandller = () => {
    dispatch(videoCameraOnAction(!videoCameraOn));
  };

  const audioHandler = () => {
    dispatch(audioAction(!audioOn));
  };
  const screenRepresentHandller = () => {
    dispatch(screenRecordAction(true));
  };
  const screenRepresentStopHandller = () => {
    dispatch(screenRecordAction(false));
  };
  const endMeetingHandller = () => {
    navigate(`/leftmeeting/${meetingId}`);
  };

  return (
    <Box>
      {isSmScreen ? (
        <Box>
          <MoreAction
            open={open}
            id={id}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
          <MoreOptions
            open={Boolean(moreOptionsAnchorEl)}
            anchorEl={moreOptionsAnchorEl}
            handleClose={handleMoreOptionsClose}
          />
          <IconButtonWithToolTip
            btnIccon={<MicNoneOutlined />}
            tootTip={"Turn off microphone"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={
              videoCameraOn ? (
                <VideocamOutlined onClick={videoCamerActionHandller} />
              ) : (
                <VideocamOffOutlinedIcon onClick={videoCamerActionHandller} />
              )
            }
            tootTip={videoCameraOn ? "Turn off camera" : "Turn on camera"}
            className={
              videoCameraOn
                ? styles.callToActionButtonStyle
                : styles.callEndButton
            }
          />
          <IconButtonWithToolTip
            btnIccon={
              screenRecordOn ? (
                <PresentToAllIcon
                  sx={{ cursor: "not-allowed", color: "gray" }}
                />
              ) : (
                <PresentToAllIcon onClick={screenRepresentHandller} />
              )
            }
            tootTip={screenRecordOn ? "" : "Present now"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={<MoreVertIcon aria-describedby="more-options-popover" onClick={handleClick} />}
            tootTip={"More options"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={<CallEndIcon />}
            tootTip={"Leave call"}
            className={styles.callEndButton}
            onClick={endMeetingHandller}
          />
          <IconButtonWithToolTip
            btnIccon={<ExpandLessIcon aria-describedby="more-options-popover" onClick={handleMoreOptionsClick} />}
            tootTip={"More Options"}
            className={styles.callToOptionStyle}
          />
          <MoreOptions
            open={Boolean(moreOptionsAnchorEl)}
            anchorEl={moreOptionsAnchorEl}
            handleClose={handleMoreOptionsClose}
          />
        </Box>
      ) : isLgScreen ? (
        <Box>
          <MoreAction
            open={open}
            id={id}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
          <IconButtonWithToolTip
            btnIccon={
              audioOn ?
            <MicOutlined  onClick={audioHandler} />
          : <MicOffOutlined  onClick={audioHandler} />
          }
            tootTip={"Turn off microphone"}
            className={
              audioOn ?
              styles.callToActionButtonStyle
            :
            styles.callEndButton
            }
          />
          <IconButtonWithToolTip
            btnIccon={
              videoCameraOn ? (
                <VideocamOutlined onClick={videoCamerActionHandller} />
              ) : (
                <VideocamOffOutlinedIcon onClick={videoCamerActionHandller} />
              )
            }
            tootTip={videoCameraOn ? "Turn off camera" : "Turn on camera"}
            className={
              videoCameraOn
                ? styles.callToActionButtonStyle
                : styles.callEndButton
            }
          />
          <IconButtonWithToolTip
            btnIccon={<ClosedCaptionOffOutlinedIcon />}
            tootTip={"Turn on captions"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={<MoodIcon />}
            tootTip={"Send a reaction"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={
              screenRecordOn ? (
                <StopScreenShareIcon onClick={screenRepresentStopHandller} />
              ) : (
                <PresentToAllIcon onClick={screenRepresentHandller} />
              )
            }
            tootTip={screenRecordOn ? "Stop Presenting" : "Present now"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={<BackHandIcon />}
            tootTip={"Raise hand"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={<MoreVertIcon aria-describedby="more-options-popover" onClick={handleClick} />}
            tootTip={"More options"}
            className={styles.callToActionButtonStyle}
          />
          <IconButtonWithToolTip
            btnIccon={<CallEndIcon />}
            tootTip={"Leave call"}
            className={styles.callEndButton}
            onClick={endMeetingHandller}
          />
          
        </Box>
      ) : null}
    </Box>
  );
};

export default CallAndActionSection;
