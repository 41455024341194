import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { screenRecordAction } from "../../store/action";
import styles from "../../style/mediaRecorder.module.css";
import { callUser } from "../../store/socketThunks";

const ScreenRecorder = ({ meetingActivyAction, stopScreenSharing, startScreenSharing}) => {
  const { screenRecordOn ,} = useSelector((state) => state.reducerMeetingType);
  const { roomUsers } = useSelector(state => state.socketReducer);


  const dispatch = useDispatch();
  const [mediaStream, setMediaStream] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {


    const startScreenRecording = async () => {
     
      try {
        if (!mediaStream) {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            video: { cursor: "always" },
            audio: true,
          });
          setMediaStream(stream);
          videoRef.current.srcObject = stream;
          startScreenSharing(stream)
          const userString = localStorage.getItem('user')
          const u = JSON.parse(userString).user

          const newroomUsers = roomUsers.filter(user => user.userID !== u._id)
         

          // Add an "ended" event listener to detect manual stop
          stream.getVideoTracks()[0].onended = () => {
            console.log("reacoding is stoped")
            dispatch(screenRecordAction(false));
            stopScreenRecording();
           
          };
        }
      } catch (err) {
        console.error("Error accessing screen recording:", err);
        dispatch(screenRecordAction(false));
      }
    };


    const stopScreenRecording = () => {

      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        setMediaStream(null);
        stopScreenSharing()
        dispatch(screenRecordAction(false));
      }
    };

    if (screenRecordOn && !mediaStream) {
      startScreenRecording();
    } else if (!screenRecordOn && mediaStream) {
      stopScreenRecording();
      stopScreenSharing()
    }

    return () => {
      stopScreenRecording();
      

    }
  }, [screenRecordOn, dispatch, mediaStream]);

  return (
    <video
      style={{ width: '100%', height: "100%" }}
      className={
        meetingActivyAction
          ? styles.screenRecordVideoResponsive
          : styles.screenRecordVideo
      }
      ref={videoRef}
      controls={false}
      autoPlay
    />
  );
};

export default ScreenRecorder;
