import React, { useRef, useEffect, useState, useMemo } from "react";
import io from 'socket.io-client';
import Peer from 'peerjs';
import VideoAndPresentingSection from "./VideoAndPresentingSection";
import StartMeetingFooterSection from "./StartMeetingFooterSection";
import { useDispatch, useSelector } from 'react-redux';
import { addVideoStream, broadcastMessageAction, newMessage, removeVideoStream, screen } from "../../store/action";
import { usePeerConnection } from "../../hooks/usePeerConnection";
import { useSocketConnection } from "../../hooks/useSocketConnection";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import BASE_URL from "../../utilities/baseUrl";

const Loading = ({ message}) => {

  return (
    <>
      <CssBaseline />
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item>
          <CircularProgress style={{ marginRight: "30px" }} />
        </Grid>
        <Grid item>{message}</Grid>
      </Grid>
    </>
  );
};
const StartInstantMeetingComponent = () => {

  const location = useLocation();
  const meetingData = location.state?.meetingData;

  const { audioOn, videoCameraOn, screenRecordOn, joinstaus } = useSelector((state) => state.reducerMeetingType);
  const { isConnected } = useSelector(state => state.socketReducer);

  const [peers, setPeers] = useState({});
  const [JointRequest, setJoinRequest] = useState({});
  const [, setVideoStreams] = useState([]);
  const [screenSharing, setScreenSharing] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const dispatch = useDispatch();
  const myVideoRef = useRef(document.createElement('video'));
  const navigate = useNavigate();
  const myPeerRef = usePeerConnection();
  const socketRef = useSocketConnection();

  const currentStreamRef = useRef(null); // Reference to hold current stream (video/screen)
  const originalStreamRef = useRef(null); // Keep the original webcam stream
  const screenStreamRef = useRef(null);   // Reference to hold the screen stream

  const onSendMessage = (message) => {

    try {
      const roomID = window.location.pathname.split('startinstantmeeting/')[1];
      const userString = localStorage.getItem('user')
      const userData = JSON.parse(userString).user
      socketRef.current.emit('broadcast-message', { message, userData, roomID });
      dispatch(broadcastMessageAction({ message, userData }));

    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };

  const handleApiResponse = async (id) => {
    try {

      setLoading(true); // Start loading
      setError(null); // Clear any previous errors
      const roomID = window.location.pathname.split('startinstantmeeting/')[1];
      const userString = localStorage.getItem('user');
      const user = JSON.parse(userString).user;
      const userData = {
        userID: user?._id,
        userName: user?.firstName + " " + user?.lastName,
        roomID,
        Peer: id,
      };
      const res = await Axios.get(`${BASE_URL}/api/v1/verify-room/${roomID}/${user?._id}`)

      if (res.data.roomExists && res.data.isHost) {
        socketRef.current.emit('create-room', userData);
      } else if (joinstaus === "room") {
        socketRef.current.emit('join-room', userData);
      }
      else if (res.data.roomExists) {
        navigate(`/joinbylink/${roomID}`);
        // socketRef.current.emit('join-room', userData);
      }
      console.log(res.data)
      setLoading(false); // Stop loading when API call completes
    } catch (err) {
      console.error('API error:', err);
      setError('Failed to load data. Please try again.'); // Set error message
      setLoading(false); // Stop loading on error
    }
  };

  useEffect(() => {
    // Check if the route matches your condition
    if (location.pathname.includes('/startinstantmeeting/')) {
      // Add CSS dynamically to hide the element
      const styleElement = document.createElement('style');
      styleElement.innerHTML = `
        .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionSticky.MuiBox-root.css-1jrx63a-MuiPaper-root-MuiAppBar-root {
          display: none;
        }
      `;
      document.head.appendChild(styleElement);

      // Clean up when component unmounts
      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [location.pathname]);

  useEffect(() => {


    const myVideo = myVideoRef.current;
    myVideo.muted = true;

    // Get the original webcam stream
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    }).then((stream) => {
      originalStreamRef.current = stream;
      currentStreamRef.current = stream; // Start with the webcam stream
      const videoTracks = currentStreamRef.current.getVideoTracks();

      if (videoTracks.length > 0) {
        videoTracks.forEach(track => {
          track.enabled = videoCameraOn;
        });
      }
      const userS = localStorage.getItem('user');
      const use = JSON.parse(userS).user;
      const userName = use?.firstName + " " + use?.lastName
      const id = use._id

      dispatch(addVideoStream('Me', stream, userName, id));
      // addVideoStream('Me', stream, userName); // Add self video stream

      // Answer incoming calls
      myPeerRef.current.on('call', (call) => {
        call.answer(currentStreamRef.current);
        const username = call.metadata.username;
        const id = call.metadata.id;
        const userId = call.peer;

        call.on('stream', (userVideoStream) => {
          dispatch(addVideoStream(userId, userVideoStream, username, id));
        });
      });

      // Handle user connection via socket
      socketRef.current.on('user-connected', (data) => {
        connectToNewUser(data.Peer, currentStreamRef.current, data.userName, data.id);
      });

      socketRef.current.on('new-user-connected', (data) => {
        if (data.Peer !== myPeerRef.current.id) {
          connectToNewUser(data.Peer, currentStreamRef.current, data.userName, data.id);
        }
      });

      const roomID = window.location.pathname.split('startinstantmeeting/')[1];
      // Join the room with the current peer ID
      const userString = localStorage.getItem('user');
      const user = JSON.parse(userString).user;
      const username = `${user.firstName} ${user.lastName}`
      const data = { roomID, Peer: myPeerRef.current.id, userName: username, id: user._id }

      socketRef.current.emit('connection-request', data);

      return () => {
        socketRef.current.disconnect();
        myPeerRef.current.destroy();
      };
    });

    socketRef.current.on('user-disconnected', (userId) => {

      if (peers[userId]) {
        peers[userId].close();
        setPeers((prevPeers) => {
          const updatedPeers = { ...prevPeers };
          delete updatedPeers[userId];
          return updatedPeers;
        });
      }
      dispatch(removeVideoStream(userId));
      // Remove the disconnected user's video from the state
      //   setVideoStreams((prevStreams) => prevStreams.filter(stream => stream.userId !== userId));
    });

    myPeerRef.current.on('open', (id) => {
      handleApiResponse(id)
      /*    const roomID = window.location.pathname.split('startinstantmeeting/')[1];
         const userString = localStorage.getItem('user')
         const user = JSON.parse(userString).user
         const userData = {
           userID: user?._id,
           userName: user?.firstName + " " + user?.lastName,
           roomID,
           Peer: id
         };
   
         if(joinstaus === "creation"){
           socketRef.current.emit('create-room', userData);
         }else if (joinstaus === "room"){
             socketRef.current.emit('join-room', userData);
         }
         else{
          navigate(`/joinbylink/${roomID}`);
          // socketRef.current.emit('join-room', userData);
         } */


    });
  }, []);

  useEffect(() => {
    // Assuming socketRef.current is already initialized with a socket connection
    const handleNewMessage = (data) => {
      dispatch(newMessage(data));

    };
    const handleNewScreensharing = (data) => {
      dispatch(screen(data));
      console.log(data, "new-screensharing-message")
    };
    const handleJoin = (data) => {
      setJoinRequest(data)
      // console.log(data,"JointRequest")
    };
    socketRef.current.on('new-broadcast-messsage', handleNewMessage);
    socketRef.current.on('new-screensharing-message', handleNewScreensharing);
    socketRef.current.on('join-request', handleJoin);
    // Clean up the event listener when the component unmounts
    return () => {
      socketRef.current.off('new-broadcast-messsage', handleNewMessage);
      socketRef.current.off('new-screensharing-message', handleNewScreensharing);
      socketRef.current.off('join-request', handleJoin);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!currentStreamRef.current) {
      console.log('currentStreamRef.current is null, cannot update video stream');
      return;
    }

    // Enable or disable the video tracks based on videoCameraOn state
    const videoTracks = currentStreamRef.current.getVideoTracks();

    if (videoTracks.length > 0) {
      videoTracks.forEach(track => {
        track.enabled = videoCameraOn;
      });
    } else {
      console.log('No video tracks found in the current stream.');
    }

    // Update the stream for each peer when toggling video
    Object.values(peers).forEach((peer) => {
      if (!peer || !peer.peerConnection) {
        console.log('Peer or peerConnection is null or undefined:', peer);
        return;
      }

      const senders = peer.peerConnection.getSenders();

      if (!senders || senders.length === 0) {
        console.log('No senders found for peer connection:', peer.peerConnection);
        return;
      }

      senders.forEach((sender) => {
        if (!sender || !sender.track) {
          console.log('Sender or sender.track is null or undefined:', sender);
          return;
        }

        if (sender.track.kind === 'video') {
          const newVideoTrack = videoCameraOn ? currentStreamRef.current.getVideoTracks()[0] : null;

          if (newVideoTrack) {
            try {
              sender.replaceTrack(newVideoTrack);
            } catch (error) {
              console.error('Error replacing video track:', error);
            }
          } else {
            console.log('New video track is null, cannot replace.');
          }
        }
      });
    });
  }, [videoCameraOn, peers]);

  const startScreenSharing = (stream) => {
    setScreenSharing(true);

    const userString = localStorage.getItem('user')
    const user = JSON.parse(userString).user

    const roomID = window.location.pathname.split('startinstantmeeting/')[1];
    socketRef.current.emit('screensharing-message', { roomID, screenRecordOn, id: user._id });
    screenStreamRef.current = stream;
    currentStreamRef.current = stream;

    // Stop any tracks from the webcam stream that were replaced by the screen stream
    const videoTrack = stream.getVideoTracks()[0];
    console.log(videoTrack, "videoTrack")
    //  videoTrack.onended = stopScreenSharing; // Handle the case when the screen sharing ends
    Object.values(peers).forEach((peer) => {
      const senders = peer.peerConnection.getSenders();
      senders.forEach((sender) => {
        if (!sender || !sender.track) {
          console.log('Sender or sender.track is null or undefined:', sender);
          return; // Skip if the sender or track is invalid
        }
        if (sender.track.kind === 'video') {
          sender.replaceTrack(videoTrack);
        }
      });
    });

    // Set screen sharing state to true
    setScreenSharing(true);
    //  setLoadingScreenShare(false); // Hide loading indicator

  };

  useEffect(() => {
    if (screenSharing && currentStreamRef.current !== screenStreamRef.current) {
      currentStreamRef.current = screenStreamRef.current;
    } else if (!screenSharing && currentStreamRef.current !== originalStreamRef.current) {
      currentStreamRef.current = originalStreamRef.current;
    }
    Object.values(peers).forEach((peer) => {
      if (!peer || !peer.peerConnection) {
        console.log('Peer or peerConnection is null or undefined:', peer);
        return;
      }


      const senders = peer.peerConnection.getSenders();
      senders.forEach((sender) => {

        if (!sender || !sender.track) {
          console.log('Sender or sender.track is null or undefined:', sender);
          return; // Skip if the sender or track is invalid
        }
        if (sender.track.kind === 'video') {
          const newTrack = screenSharing ? screenStreamRef.current.getVideoTracks()[0] : originalStreamRef.current.getVideoTracks()[0];
          sender.replaceTrack(newTrack);
        }
      });
    });
  }, [screenSharing, peers]);

  const stopScreenSharing = () => {

    const userString = localStorage.getItem('user')
    const user = JSON.parse(userString).user

    const roomID = window.location.pathname.split('startinstantmeeting/')[1];
    socketRef.current.emit('screensharing-message', { roomID, screenRecordOn: false, id: user._id });
    if (screenStreamRef.current) {
      const tracks = screenStreamRef.current.getTracks();
      tracks.forEach(track => track.stop()); // Stop the screen share tracks
    }
    currentStreamRef.current = originalStreamRef.current;
    const videoTrack = originalStreamRef.current.getVideoTracks()[0];
    Object.values(peers).forEach((peer) => {
      const senders = peer.peerConnection.getSenders();
      senders.forEach((sender) => {
        if (sender.track.kind === 'video') {
          sender.replaceTrack(videoTrack);
        }
      });
    });

    // Set screen sharing state to false
    setScreenSharing(false);
  };

  useEffect(() => {
    if (currentStreamRef.current) {

      const audioTracks = currentStreamRef.current.getAudioTracks();

      if (audioTracks.length > 0) {
        audioTracks.forEach(track => {
          track.enabled = audioOn;
        });
      }

      // Update the stream for each peer when toggling audio
      Object.values(peers).forEach((peer) => {
        if (peer && peer.peerConnection) {
          peer.peerConnection.getSenders().forEach((sender) => {
            if (sender.track && sender.track.kind === 'audio') {
              const newAudioTrack = audioOn ? currentStreamRef.current.getAudioTracks()[0] : null;

              // Check if there is a valid audio track before replacing
              if (newAudioTrack) {
                sender.replaceTrack(newAudioTrack);
              }
            }
          });
        }
      });
    }
  }, [audioOn, peers]);

  const handleAdmit = () => {
    setJoinRequest({})
    socketRef.current.emit('handle-join-request', {
      roomID: JointRequest.roomID,
      accepted: true,
      userName: JointRequest.userName,
      socketID: JointRequest.socketID
    });

  }
  const connectToNewUser = (userId, stream, userName, id) => {
    const userString = localStorage.getItem('user')
    const user = JSON.parse(userString).user
    const username = user?.firstName + " " + user?.lastName

    const call = myPeerRef.current.call(userId, stream, {
      metadata: { username, id: user._id }
    });

    call.on('stream', (userVideoStream) => {

      dispatch(addVideoStream(userId, userVideoStream, userName, id));
    });

    call.on('close', () => {
      dispatch(removeVideoStream(userId));

    });

    setPeers((prevPeers) => ({
      ...prevPeers,
      [userId]: call,
    }));
  };

  if (loading) {
    return <Loading message="Loading..." />;
  }

  return (
    <div>

      <VideoAndPresentingSection
        onSendMessage={onSendMessage}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
        JointRequest={JointRequest}
        handleAdmit={handleAdmit}
      />
      <StartMeetingFooterSection />
    </div>
  );
};

export default StartInstantMeetingComponent;
