import React from "react";
import styles from "../../style/startInstantMeeting.module.css";
import MeetingReadyDialogBox from "./MeetingReadyDialogBox";
import MeetingDetailsBox from "./MeetingDetailsBox";
import { useSelector } from "react-redux";
import MediaRecorder from "../MediaRecorder";
import MeetingJoinDialogBox from "./MeetingJoinDialogBox";


const VideoAndPresentingSection = ({
  onSendMessage,
  stopScreenSharing,
  startScreenSharing,
  JointRequest,
  handleAdmit
}) => {
  const { meetingActivyAction } = useSelector(
    (state) => state.reducerMeetingType
  );

  return (
    <div className={styles.videoAndPresentingSectionDiv}>
      {/* VideoAndPresentingSection */}
      
      <MediaRecorder startScreenSharing={startScreenSharing} stopScreenSharing={stopScreenSharing} />
      <MeetingReadyDialogBox />
      <MeetingJoinDialogBox JointRequest={JointRequest} handleAdmit={handleAdmit}/>
      {meetingActivyAction && <MeetingDetailsBox  onSendMessage={onSendMessage}/>}
    </div>
  );
};

export default VideoAndPresentingSection;
