import { useEffect, useRef } from 'react';
import Peer from 'peerjs';
import BASE_URL from '../utilities/baseUrl';

export const usePeerConnection = () => {
  const myPeerRef = useRef(null);

  useEffect(() => {
    // Initialize Peer only once on component mount
    const peer = new Peer(undefined, {
      host: new URL(BASE_URL).hostname, // Get the hostname (e.g., 'localhost')
      port: new URL(BASE_URL).port,     // Get the port (e.g., '4000')
      path: '/peerjs',
      secure: new URL(BASE_URL).protocol === 'https:', // Determine if it's secure (https) or not (http)
    });

    myPeerRef.current = peer;

    // Cleanup peer connection on component unmount
    return () => {
      if (myPeerRef.current) {
        myPeerRef.current.destroy();
      }
    };
  }, []);

  return myPeerRef;
};
