import { Box, Card, Button, Stack, Typography, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import AddPeopleIconBtn from "../common/AddPeopleIconBtn";


const MeetingJoinDialogBox = ({JointRequest,handleAdmit}) => {

  const [dialogOpen, setDialogOpen] = useState(false);


  useEffect(() => {
    // Open the dialog when JointRequest is received
    if (JointRequest && JointRequest.roomID) {
      setDialogOpen(true);
    }else{
      setDialogOpen(false);
    }
  }, [JointRequest]);  


  return (
    <div>
      <Card
        style={
          dialogOpen
            ? {
              position: "absolute",
              right: "0px",
              bottom: "5px",
              padding: "15px",
              borderRadius:"10px",
            }
            : { display: "none" }
        }
        sx={{ width: 350 }}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" ml={1}>
              Someone wants to join this call
            </Typography>
            {/*  <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton> */}
          </Stack>
        </Box>

        <Box sx={{ margin: "5px 12px" }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              // className={styles.avatar}
              sx={{ height: "35px", width: "35px", fontSize: "20px" }}
              src={"src"}  // Replace this with the image source if available
            >
              {JointRequest && JointRequest?.userName?.charAt(0).toUpperCase()}
            </Avatar>
            <Typography variant="body1" sx={{ fontSize: "16px", fontWeight:"500"}}>
              {JointRequest?.userName}
            </Typography>
          </Stack>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="text"  // Removed the border by setting variant to "text"
              color="primary"
             onClick={handleAdmit}
            >
              Admit
            </Button>
            <Button
              variant="text"  // Removed the border by setting variant to "text"
              color="primary"
            // onClick={handleView}
            >
              View
            </Button>
          </Stack>
        </Box>



      </Card>
    </div>
  );
};

export default MeetingJoinDialogBox;
