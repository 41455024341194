import { io } from "socket.io-client";
import Peer from 'peerjs';
import {
    socketConnect,
    socketDisconnect,
    socketError,
    newMessage,
    displayMedia,
    roomUser,
    updateStreamingStatus,
    broadcastMessageAction,
    remoteStreamsOnAction
} from './action';


const websocket = "http://localhost:5000/";
const peerjsEndpoint = "localhost";
let socket, myPeer;



export const initializeSocketConnection = () => async (dispatch) => {
    socket = io(websocket);
    socket.connect()

    socket.on('connect', () => {
        console.log('Socket connected');
        dispatch(socketConnect());
    });

    socket.on('disconnect', () => {
        console.log('Socket disconnected');
        dispatch(socketDisconnect());
    });

    socket.on('error', (error) => {
        console.error('Socket error:', error);
        dispatch(socketError(error));
    });

    socket.on('new-broadcast-messsage', (data) => {
        dispatch(newMessage(data));
    });

    socket.on('display-media', (data) => {
        dispatch(displayMedia(data.value));
    });
    socket.on("room-users", (roomUsers) => {
        const userString = localStorage.getItem('user');
        const user = JSON.parse(userString).user;
      
        // Find the index of the current user
        const currentUserIndex = roomUsers.findIndex(roomUser => roomUser.userID === user._id);
      
        if (currentUserIndex > -1) {
          // Remove the current user from their original position
          const [currentUser] = roomUsers.splice(currentUserIndex, 1);
          
          // Reinsert the current user at the beginning of the array
          roomUsers.unshift(currentUser);
        }
      
        // Dispatch the updated roomUsers array
        dispatch(roomUser(roomUsers));
      });
      

 

};

export const initializePeerConnection = (currentStream) => async (dispatch) => {
    myPeer = new Peer(undefined, {
        host: peerjsEndpoint,
        port: 5000,
        path: '/peerjs',
        secure: false
    });

    myPeer.on('open', (id) => {
        console.log('Peer connection established with ID:', id);
        const roomID = window.location.pathname.split('startinstantmeeting/')[1];
        const userString = localStorage.getItem('user')
        const user = JSON.parse(userString).user
        const userData = {
            userID: user?._id,
            userName: user?.firstName + " " + user?.lastName,
            roomID,
            Peer: id
        };
        socket.emit('join-room', userData);

       /*  socket.on('user-connected', (userId) => {
            connectToNewUser(userId, currentStream)
        dispatch(callUser(peerid,currentStream))
               
         myPeer.on('call', (call) => {
            console.log("Incoming call from:", call.peer);
            const extraId = call.metadata.userID;
            console.log("Received call with extraId:", extraId);
            // Function to handle answering the call and setting up the remote stream listener
            const answerCallWithStream = () => {
                call.answer(currentStream);
                call.on('stream', (remoteStream) => {
                    dispatch(remoteStreamsOnAction((prevStreams) => ({
                        ...prevStreams,
                        [extraId]: remoteStream,
                    })));
                });
            };
    
           
        answerCallWithStream()
        }); 
          });
 */
          
        socket.on('user-connected', userId => {
            console.log('New user connection:', userId)
            dispatch( connectToNewUser(userId, currentStream))
          })
  
          socket.on('new-user-connected', userId => {
            if (userId != myPeer.id) {
              console.log("new-user-connected (2): ", userId);
              dispatch(connectToNewUser(userId, currentStream));
            }
          })
          const room = window.location.pathname.split('startinstantmeeting/')[1];
          socket.emit('connection-request', room, myPeer.id);
    //     setNavigatorToStream(dispatch);
    });

    myPeer.on('call', (call) => {
        console.log("Incoming call from:", call.peer);
      
        // Function to handle answering the call and setting up the remote stream listener
        const answerCallWithStream = () => {
            call.answer(currentStream);
            call.on('stream', (remoteStream) => {
                dispatch(remoteStreamsOnAction((prevStreams) => ({
                    ...prevStreams,
                    [call.peer]: remoteStream,
                })));
            });
        };

       
    answerCallWithStream()
    });
/* 
    socket.on("room-users", (roomUsers) => {
       // dispatch(roomUser(roomUsers));
        roomUsers.forEach((user) => {
            if (user.userID !== myPeer.id) {
          //    dispatch(callUser(user.Peer,currentStream.current))
              try {
                const call = myPeer.call(user.Peer, currentStream.current, { metadata: { userID:user.userID} });
                call.on("stream", (remoteStream) => {
                    console.log(remoteStream, "callUserstream");
                    dispatch(remoteStreamsOnAction((prevStreams) => ({
                        ...prevStreams,
                        [user.userID]: remoteStream,
                    })));
                });
            } catch (error) {
                console.error('Call error:', error);
            }
            }
          });
    }); */


    myPeer.on('error', (error) => {
        console.error('Peer error:', error);
        if (myPeer.disconnected) {
            myPeer.reconnect();
        }
    });
};

export const connectToNewUser = (userId, stream) => async (dispatch, getState) => {
  
    const call = myPeer.call(userId, stream);
    const { remoteStreams } = getState(); // Get the remoteStreams slice of the state
    const updatedStreams = {
      ...remoteStreams,
      [userId]: call, // Add or update the user's stream
    };
    dispatch(remoteStreamsOnAction(updatedStreams));
};



export const callUser = (userId, currentStream) => async (dispatch) => {
/*     if (myPeer.disconnected) {
        console.warn("Cannot call user; Peer is disconnected.");
        return;
    } */
        console.log("Calling user:", userId, "with stream:", currentStream);
    try {
        const userString = localStorage.getItem('user')
        const user = JSON.parse(userString).user
        const call = myPeer.call(userId, currentStream, { metadata: { userID:user._id } });
        call.on("stream", (remoteStream) => {
            console.log(remoteStream, "callUser");
            // Handle the remote stream as needed
        });
    } catch (error) {
        console.error('Call error:', error);
    }
};

/*   export const AnswerUser = () => async (dispatch, getState) => {
  
        try {
            myPeer.on("call", (call) => {
             
                        call.answer(null); // Answer the call with an A/V stream.
                        call.on("stream", (remoteStream) => {
                            console.log("stream",remoteStream,call.peer)
                            dispatch(remoteStreamsOnAction((prevStreams) => ({
                                ...prevStreams,
                                [call.peer]: remoteStream,
                            })));
                        });
            });
          } catch (error) {
           console.log('AnswerUser',error)
          }
}
 */

export const broadcastMessage = (message) => async (dispatch, getState) => {
    try {

        const userString = localStorage.getItem('user')
        const userData = JSON.parse(userString).user
        dispatch(broadcastMessageAction({ message, userData }));
        socket.emit('broadcast-message', { message, userData });
    } catch (error) {
        console.error('Error accessing media devices:', error);
    }
};

export const dicconectedPeer = () => {
    if (myPeer) {
        myPeer.disconnect(); // Add this to clean up connections
    }
}



/*  const setNavigatorToStream = (dispatch) => {
   
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then((audioStream) => {
            myPeer.on('call', (call) => {
                call.answer(audioStream);
                call.on('stream', (remoteStream) => {
                    dispatch(remoteStreamsOnAction((prevStreams) => ({
                        ...prevStreams,
                        [call.peer]: remoteStream,
                    })));
                });
            });
 }).catch(console.error);
}; */
